import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Make Up Day.`}</p>
    <p><strong parentName="p">{`*`}{`This Sunday we will be doing “Fires Gone Bad”, a fundraising workout
to support those affected by the fires in Australia.  `}</strong></p>
    <p><strong parentName="p">{`If you’d like to donate you can
at: `}<a parentName="strong" {...{
          "href": "https://fundraise.redcross.org.au/fundraiser/crossfitperthcomau"
        }}>{`https://fundraise.redcross.org.au/fundraiser/crossfitperthcomau`}</a></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      